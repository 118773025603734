<template>
  <div class="auftrag-erfolgreich-erfasst">
    <b-row>
      <b-col>
        <h1>Auftrag '{{$route.params.auftragsnummer}}' erfolgreich erfasst.</h1>
        <b-dropdown split text="Weiteren Auftrag erfassen" :split-to="{ name: 'auftragsformular', params: { produkttyp: Energiearten.Strom , kundentyp: Kundentyp.Privat}}" variant="primary">
          <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energiearten.Strom , kundentyp: Kundentyp.Privat}}">Strom Privat erfassen</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energiearten.Strom , kundentyp: Kundentyp.Gewerbe}}">Strom Gewerbe erfassen</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energiearten.Gas , kundentyp: Kundentyp.Privat}}">Gas Privat erfassen</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'auftragsformular', params: { produkttyp: Energiearten.Gas , kundentyp: Kundentyp.Gewerbe}}">Gas Gewerbe erfassen</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {DropdownPlugin} from 'bootstrap-vue'
  import {Energiearten, Kundentyp} from '../../../common/constants'

  Vue.use(DropdownPlugin)
  export default {
    name: 'AuftragErfolgreichErfasst',
    data() {
      return {
        Energiearten: Energiearten,
        Kundentyp: Kundentyp
      }
    }
  }
</script>

<style scoped>

</style>
